<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card outlined class="mt-0">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span v-if="vendor != '*'" class="text-truncate"> {{ vendor + " : " }}List Of Products </span>
        <span v-else class="text-truncate">
          Products
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row class="mt-3" dense>
          <v-col cols="12">
            <v-text-field
              append-outer-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @change="search = $event"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
          </v-col>
          <v-col cols="12">
            <v-data-table
              dense
              fixed-header
              locale="en"
              hide-default-footer
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :search="search"
              :headers="headers"
              :items="vendors"
              class="elevation-1"
              @pagination="totalResults = $event.itemsLength"
            >
              <template #item.vce_entries="{ item }">
                <router-link :to="'/vulnerabilities-search/vendor-' + item.vendor_name + '/product-' + item.product">
                  {{ item.vce_entries }}
                </router-link>
              </template>
              <template #item.product_type="{ item }">
                <template v-if="item.product_type == 'a'">
                  Application
                </template>
                <template v-else-if="item.product_type == 'h'">
                  Hardware
                </template>
                <template v-else-if="item.product_type == 'o'">
                  OS
                </template>
                <template v-else>
                  {{ item.product_type }}
                </template>
              </template>
            </v-data-table>
          </v-col>
          <v-col class="mt-2 flex-wrap" cols="12">
            <div style="width:200px" class="float-sm-left d-flex mb-2">
              <v-select
                v-model="itemsPerPage"
                style="width:85px"
                class="px-2 py-0 my-0 "
                :items="rowsPerPageItems"
                flat
                hide-details
              />
              <span class="mt-2  text-body-1">
                {{ (page - 1) * itemsPerPage + 1 }}-{{
                  totalResults > page * itemsPerPage ? page * itemsPerPage : totalResults
                }}
                {{ "of" }} {{ totalResults }}
              </span>
            </div>
            <v-pagination
              v-model="page"
              class="d-flex"
              :length="Math.ceil(totalResults / itemsPerPage)"
              total-visible="7"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      vendors: [],
      vendor: "",
      loading: true,
      search: "",
      page: 1,
      itemsPerPage: 20,
      totalResults: 10,
      rowsPerPageItems: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 50, value: 50 }
      ],
      headers: [
        { text: "Product Name", value: "product" },
        { text: "Vendor Name", value: "vendor_name" },
        { text: "# OF CVE Entries", value: "vce_entries", align: "right" },
        { text: "Product Type", value: "product_type" }
        // { text: "Vulnerabilities", value: "vulnerabilities", align: "right" },
        // { text: "Patches", value: "patches", align: "right" },
        // { text: "Compliance", value: "compliance", align: "right" },
        // { text: "Inventory", value: "inventory", align: "right" }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.$log("route")
      this.refreshPage()
    }
  },
  created() {
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "VendorProducts") {
        this.vendor = this.$route.params.pathMatch
        this.getRecords()
      } else if (this.$route.name == "Products") {
        if (this.vendor != "*") {
          this.vendor = "*"
          this.getRecords()
        }
      }
    },
    getRecords() {
      this.vendors = []
      this.loading = true
      //.get("cve_list_vendors/" + ((this.page - 1) * this.itemsPerPage + 1) + "/" + this.itemsPerPage)
      axios
        .get("cve_list_vendor_products/1000/1/" + this.vendor)
        .then(response => {
          this.vendors = response.data
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
      // axios
      //   .get("cve_list_products/0/1")
      //   .then(response => {
      //     //this.vendors = response.data
      //     for (const itm in response.data) {
      //       this.vendors.push({
      //         ...response.data[itm],
      //         product: itm
      //       })
      //     }
      //     this.$log("products list ", response)
      //     this.loading = false
      //   })
      //   .finally(() => {
      //     this.loading = false
      //   })
    }
  }
}
</script>
<style></style>
